<template>
    <div>
        <Head :title="'厂内调度接单'" :out="'guide'" />
        <!-- @rendered="tabsChange" @click="tabsChange" -->
        <van-tabs class="vanTabs" v-model="active" @click="ChangeHan" >
            <van-tab title="任务大厅"><carList ref="ListRef" /></van-tab>
            <van-tab title="待运送"><ToDeliver ref="DeliverRef" /></van-tab>
            <van-tab title="待确认"><ToBeConfirmed ref="ConfirmedRef" /></van-tab>
            <van-tab title="已完成"><OffTheStocks ref="StocksRef" /></van-tab>
        </van-tabs>
          
    </div>
</template>

<script>
import Head from '@/components/Head'
import carList from './carList.vue'
import ToDeliver from './ToDeliver'
import ToBeConfirmed from './ToBeConfirmed'
import OffTheStocks from './OffTheStocks'
    export default {
        components: {
            Head,carList,ToDeliver,ToBeConfirmed,OffTheStocks
        },
        data() {
            return {
                active:0
            }
        },
        mounted () {
            if(this.$route.query.active == 2){
                this.active = 2
            }
            this.ChangeHan()
        },
        methods: {
            ChangeHan(){
                if(this.active == 0){
                    this.$nextTick(()=>{
                        this.$refs.ListRef.lodData()
                    })
                }else if(this.active == 1){
                    this.$nextTick(()=>{
                        this.$refs.DeliverRef.lodData()
                    })
                }else if(this.active == 2){
                    this.$nextTick(()=>{
                        this.$refs.ConfirmedRef.lodData()
                    })
                }else if(this.active == 3){
                    this.$nextTick(()=>{
                        this.$refs.StocksRef.lodData()
                    })

                }
            },
            tabsChange(e) {
                
            }
        },
    }
</script>

<style lang="scss" scoped>
.vanTabs{
    margin-top: 50px;
}
</style>