<template>
   <div class="warper">
         <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                :immediate-check="false"
                @load="onLoad"
                :offset="200"
            >
            <div class="content-infp" v-for="item in list" :key="item">
                <p>{{item.createTime}}</p>
                <div class="info" style="display: flex;">
                    <img style="width:100px;height:100px;flex:1" :src="item.vehicleImages0?item.vehicleImages0:imgs" alt="">
                    <div style="flex:2;margin-left: 10px">
                        <p class="tit">{{item.working_procedure_name}}</p>
                        <p style="font-weight: 700;">{{item.defined_num?'自定义编号':'车牌号'}}：{{item.defined_num?item.defined_num:item.carNumberPlate}}</p>
                        <p><span>起点工位：</span>{{item.start_position}}</p>
                        <p><span>终点工位：</span>{{item.end_position}}</p>
                    </div>
                </div>
                <p>
                    <span>下单员工：</span>{{item.realName}}
                </p>
                <!-- <p>
                    <span>联系电话：</span>{{item.phone}} 
                    <a  class="bid" :href="'tel:' + item.phone">
                        <van-icon name="phone-o" />
                    </a>
                </p> -->
                <p>
                    <span>送达时间：</span>{{item.end_time}} 
                </p>
                <p class="btn-p">
                    <van-button type="primary" @click="orderChange(item)">联系下单员工</van-button>
                </p>
            </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import {forkliftToBeConfirmed} from '@/api/control'
import { Toast,Dialog } from 'vant'
    export default {
        data() {
            return {
                 refreshing: false,//上啦的
                loading: false,//下拉等待圈圈
                finished: true,//下拉的状态 
                list:[],
                page:0,
                pageSize:10,
                imgs:require('../../assets/zw.jpg')
            }
        },
        methods: {
            orderChange(val) {
                window.location.href= `tel: ${val.phone}`
            },
             lodData(){
               
                 this.list = [] 
                 this.page = 0
                 this.onLoad()
            },
            //加载 上拉
            onRefresh() {
                this.page = 0
                // 清空列表数据
                this.list = []
                this.finished = false;
                this.refreshing = false
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
            },
             //下拉
            onLoad(){
                let this_ = this
                let toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                setTimeout(async () => {
                    try{
                        let page = this_.page + 1
                        this_.page = page
                        let pageSize = this_.pageSize
                        let obj={
                            pageSize,page
                        }
                        const {data} = await forkliftToBeConfirmed(obj).then(res=>res)
                        toast.clear();
                        if(data.data.data.length==0){
                            //this_.list = [] 
                            this_.finished = true;
                            this_.refreshing = false
                        }else{
                            
                            let arr = data.data.data
                            arr.forEach(element => {
                                this_.list.push(element)
                            });
                            if(this_.list.length >= data.data.total){
                                this_.finished = true;
                                this_.refreshing = false
                            }else{
                                this_.finished = false;
                                // this_.refreshing = false
                            }
                        }
                        this_.loading = false;
                    }
                    catch{
                        this_.finished = true;
                        this_.refreshing = false
                        toast.clear();
                        this_.$toast.fail('请求出错！')
                    }
                }, 1000);

            },
        },
    }
</script>

<style lang="scss" scoped>
.warper{
    height: 70vh;
    padding: 10px;
    .content-infp{
        margin-top: 10px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #eee;
        .info{
            
            .tit{
                font-size: 23px;
                font-weight: 700;
            }   
        }
        .btn-p{
            text-align: center;
            .van-button{
                margin: 0 5px;
            }
        }
    }
}
.bid{
    padding: 5px 10px;
    background-color: #008eff;
    color: white;
    cursor: pointer;
    float: right;
    border-radius: 8px;
    font-size: 20px;
}
</style>