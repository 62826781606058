import request from './request'

//工序工位
export function forkliftTask(data) {
  return request({
    url: '/index.php/phone_pretreatment/forklift_task',
    method: 'post',
    data
  })
}
export function forkliftTakeOrders(data) {
  return request({
    url: '/index.php/phone_pretreatment/forklift_take_orders',
    method: 'post',
    data
  })
}
export function forkliftToBeCompleted(data) {
  return request({
    url: '/index.php/phone_pretreatment/forklift_to_be_completed',
    method: 'post',
    data
  })
}
export function forkliftCompleted(data) {
  return request({
    url: '/index.php/phone_pretreatment/forklift_completed',
    method: 'post',
    data
  })
}
export function forkliftToBeConfirmed(data) {
  return request({
    url: '/index.php/phone_pretreatment/forklift_to_be_confirmed',
    method: 'post',
    data
  })
}
export function forkliftOver(data) {
  return request({
    url: '/index.php/phone_pretreatment/forklift_over',
    method: 'post',
    data
  })
}
